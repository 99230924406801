<template>
  <div class="service-category-setting">
    <PageTitle
      title="服務類別設定"
      cyBtn1="new-category-btn"
      btn="新增"
      @btnClick="openDialog('create')"
    />

    <FiltersContainer>
      <el-input v-model="nameSearch" clearable placeholder="輸入類別名稱" @keypress.enter.native="refresh" @clear="refresh">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh" />
      </el-input>
    </FiltersContainer>

    <section>
      <el-table v-loading="loading" :data="serviceCategoryList" empty-text="暫無數據">
        <EmptyBlock slot="empty" />
        <el-table-column prop="name" label="圖片" align="center">
          <template slot-scope="scope">
            <img style="margin: auto;" :width="`${imgSize}px`" :src="imgSource(scope.row.Image)" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名稱" align="center" />
        <el-table-column prop="isPublic" label="公開顯示" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isPublic)">{{ (scope.row.isPublic)? '是' : '否' }}</Tag>
          </template>
        </el-table-column>
        <el-table-column prop="order" label="排序" align="center" />
        <el-table-column label="操作" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="openDialog('update'), syncFormData(scope.row), selectRow=scope.row, findServiceCategory()"
              @delete="deleteDialog=true, selectRow=scope.row"
            />
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="serviceCategoryCount"
        @pageChange="refresh"
      />
    </section>

    <image-cropper v-if="uploadDialog" :image="formData.img" @uploaded="getImage" @close="uploadDialog = false" />

    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="resetForm(), formData.services=[]"
    >
      <section>
        <el-form
          ref="form"
          :model="formData"
          label-position="top"
          label-width="150px"
          :rules="formRules"
        >
          <el-form-item label="圖片">
            <UploadButton
              cyUploadBtn="service-category-img-upload"
              cyUploadedImg="service-category-img-uploaded"
              :img="formData.avatar"
              :isAvatar="true"
              @change="loadImg"
            />
            <!-- <img :src="`https://bob-image.flightgoai-service.com/display/resize/80x/${formData.avatar.filename}`" alt="" srcset=""> -->
          </el-form-item>
          <el-form-item label="公開顯示">
            <el-switch
              v-model="formData.isPublic"
              active-text="是"
              inactive-text="否"
            />
          </el-form-item>
          <el-form-item label="名稱" prop="name">
            <el-input v-model="formData.name" :maxlength="68" show-word-limit data-cy="name-input" placeholder="請輸入類別名稱" />
          </el-form-item>

          <el-form-item label="排序" prop="order">
            <el-input v-model="formData.order" data-cy="order-input" placeholder="請輸入順序" />
          </el-form-item>

          <el-form-item label="綁定服務項目" prop="services">
            <UnitServicesSelect data-cy="service-binding-select" :model.sync="formData.services" multiple :showAll="true" />
          </el-form-item>
        </el-form>
      </section>

      <div slot="footer">
        <el-button data-cy="dialog-cancel-btn" plain @click="showDialog = false, resetForm(), formData.services=[]">
          返回
        </el-button>
        <el-button
          data-cy="dialog-confirm-btn"
          type="primary"
          @click="dialogConfirm"
        >
          {{ (dialogType === 'create')? '新增' : '儲存' }}
        </el-button>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteServiceCategory(), deleteDialog = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UnitServicesSelect from '@/components/Select/UnitServicesSelect.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation/index'
import {
  CreateServiceCategory,
  GetServiceCategory,
  GetServiceCategoryCount,
  UpdateServiceCategory,
  FindServiceCategory,
  DeleteServiceCategory,
} from '@/api/serviceCategory'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'

// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import { extractList, imgSrc } from '@/utils/helper'

// import * as cssvars from '@/styles/ohbot/_variables.scss'

export default {
  name: 'ServiceCategorySetting',
  components: { ImageCropper, UploadButton, UnitServicesSelect, DeleteDialog, EmptyBlock },
  data: () => ({
    loading: false,
    nameSearch: '',
    uploadDialog: false,
    showDialog: false,
    deleteDialog: false,
    dialogType: '',

    serviceCategoryList: [],
    serviceCategoryCount: 0,

    selectRow: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    avatarChanged: false,
    imgSize: 90,

    formData: {
      img: null,
      isPublic: true,
      avatar: null,
      name: '',
      order: 100,
      id: '',
      services: [],
    },

    formRules: {
      name: noEmptyRules('請輸入類別名稱'),
      order: [noEmptyRules('請輸入排序'), isDigitRules(), rangeRules()],

    },
  }),
  computed: {
    ...mapGetters([
      'shop',
    ]),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    dialogTitle () {
      return dialogTitle(
        this.dialogType,
        {
          create: '新增服務類別',
          update: '編輯服務類別',
        },
      )
    },
    servicesList () {
      if (this.formData.services.length === 0) return []
      return extractList('id', this.formData.services)
    },
  },
  async mounted () {
    await this.refresh()
  },

  methods: {
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      // if (!val) type = 'danger'
      return type
    },

    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    getImage (data) {
      this.formData.avatar = data
      this.avatarChanged = true
      this.uploadDialog = false
    },

    loadImg (img) {
      this.formData.img = img
      this.uploadDialog = true
    },

    async refresh () {
      this.loading = true
      await this.getServiceCategory()
      await this.getServiceCategoryCount()
      this.loading = false
    },

    syncFormData (row) {
      this.formData.name = row.name
      this.formData.order = row.order
      this.formData.id = row.id
      this.formData.isPublic = row.isPublic
      this.formData.avatar = row.Image
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        img: null,
        isPublic: true,
        avatar: null,
        name: '',
        order: 100,
        id: '',
        services: [],
      }
    },

    async dialogConfirm () {
      if (!await this.checkForm()) return
      const type = this.dialogType
      if (type === 'create') this.createServiceCategory()
      if (type === 'update') this.updateServiceCategory()
      this.showDialog = false
    },

    //= > 取得服務類別
    async getServiceCategory () {
      this.loading = true
      try {
        const startIndex = this.pageStartIndex
        const limit = this.tableOptions.pageLimit
        const res = await GetServiceCategory({
          shopId: this.shop,
          start: startIndex,
          limit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceCategoryList = res
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 創建服務類別
    async createServiceCategory () {
      this.loading = true
      if (this.shop === '') this.$message.error('請先選擇運營單位')
      try {
        await CreateServiceCategory({
          shopId: this.shop,
          name: this.formData.name,
          image: (this.formData.avatar) ? this.formData.avatar.id : undefined,
          order: this.formData.order,
          appointmentServices: this.servicesList,
        })
        await this.refresh()
        this.loading = false
        this.$message.success('新增成功')
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async findServiceCategory () {
      try {
        const res = await FindServiceCategory({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.formData.services = res.AppointmentServices.filter(item => !item.isRemove)
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    //= > 刪除服務類別
    async deleteServiceCategory () {
      try {
        this.loading = true
        await DeleteServiceCategory({
          shopId: this.shop,
          id: this.selectRow.id,
        })

        await this.refresh()
        this.loading = false
        this.$message.success('刪除成功')
      } catch (error) {
        this.$message.error({
          message: error || error.message,
        })
      }
      await this.refresh()
    },
    //= > 更新服務類別
    async updateServiceCategory () {
      try {
        this.loading = true
        await UpdateServiceCategory({
          shopId: this.selectRow.ShopId,
          id: this.selectRow.id,
          isPublic: this.formData.isPublic,
          name: this.formData.name,
          image: (this.avatarChanged) ? this.formData.avatar.id : undefined,
          order: this.formData.order,
          appointmentServices: this.servicesList,
        })
        this.loading = false
        await this.refresh()
        this.$message.success('更新成功')
      } catch (error) {
        this.$message.error(error)
        this.loading = false
      }
    },

    //= > 取得服務人員總數
    async getServiceCategoryCount () {
      try {
        this.loading = true
        const res = await GetServiceCategoryCount({
          shopId: this.shop,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceCategoryCount = res.data.count
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$message.error(error.reponse.data.msg)
      }
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style scoped lang="scss"></style>
